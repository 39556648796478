import React, { useRef, useState, useEffect } from "react";
import { useLoadOnVisible } from "../../hooks";
const ImageLoader = ({
  className,
  placeholderAspectRatio,
  image,
  webp,
  smallImage,
  smallWebp,
  gifUrl,
  svgUrl,
  svgSmUrl,
  alt,
  noLazyLoad = false,
}) => {
  const [isImgLoading, setIsImgLoading] = useState(false);
  const [awaitImgLoad, setAwaitImgLoad] = useState(false);
  const ref = useRef(null);
  const imgRef = useRef(null);
  const isInView = useLoadOnVisible(ref, { threshold: 0.2 });

  useEffect(() => {
    if (!isImgLoading) return;

    const timer = setTimeout(() => {
      setAwaitImgLoad(true);
    }, 500);

    return () => clearTimeout(timer);
  }, [isImgLoading]);

  useEffect(() => {
    if (imgRef.current && imgRef.current.complete) {
      setIsImgLoading(true);
    }
  }, [image]);

  const imgSrc = gifUrl || svgUrl || svgSmUrl || image;
  const imgType = gifUrl
    ? "image/gif"
    : svgUrl || svgSmUrl
    ? "image/svg+xml"
    : "image/png";

  return (
    <figure className={`${className}`} ref={ref}>
      {!awaitImgLoad && !noLazyLoad && (
        <div
          className="blank-placeholder"
          style={{ aspectRatio: `${placeholderAspectRatio}` }}
        />
      )}
      <picture className={!awaitImgLoad && !noLazyLoad ? "img-absolute" : ""}>
        {!gifUrl && !svgSmUrl && !svgUrl && smallWebp && smallImage && (
          <>
            <source
              key="webp"
              media="(max-width: 768px)"
              srcSet={smallWebp}
              type="image/webp"
            />
            <source
              key="png"
              media="(max-width: 768px)"
              srcSet={smallImage}
              type="image/png"
            />
          </>
        )}
        {!gifUrl && !svgUrl && !svgSmUrl && (
          <>
            <source key="webp" srcSet={webp} type="image/webp" />
            <source key="png" srcSet={image} type="image/png" />
          </>
        )}

        {svgSmUrl && (
          <>
            <source
              key="svg-sm"
              media="(max-width: 768px)"
              srcSet={svgSmUrl}
              type="image/svg+xml"
            />
          </>
        )}
        {svgUrl && (
          <>
            <source key="svg-lg" srcSet={svgUrl} type="image/svg+xml" />
          </>
        )}
        {isInView && !noLazyLoad && (
          <img
            ref={imgRef}
            src={imgSrc}
            alt={alt}
            style={{ opacity: `${awaitImgLoad ? 1 : 0}` }}
            onLoad={() => setIsImgLoading(true)}
            type={imgType}
          />
        )}
        {noLazyLoad && (
          <img
            ref={imgRef}
            src={imgSrc}
            alt={alt}
            type={imgType}
            loading="lazy"
          />
        )}
      </picture>
    </figure>
  );
};

export default ImageLoader;
